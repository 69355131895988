import React from 'react';

const OtherBuildingsIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 64 64"
    height="65"
    width="65"
  >
    <g>
      <path d="m21 37h2v2h-2z" />
      <path d="m17 37h2v2h-2z" />
      <path d="m25 37h2v2h-2z" />
      <path d="m25 41h2v2h-2z" />
      <path d="m21 41h2v2h-2z" />
      <path d="m17 41h2v2h-2z" />
      <path d="m25 45h2v2h-2z" />
      <path d="m21 45h2v2h-2z" />
      <path d="m17 45h2v2h-2z" />
      <path d="m9 41h2v2h-2z" />
      <path d="m9 45h2v2h-2z" />
      <path d="m43 17h-18v6h18zm-2 4h-14v-2h14z" />
      <path d="m53 21h2v2h-2z" />
      <path d="m57 21h2v2h-2z" />
      <path d="m57 25h2v2h-2z" />
      <path d="m53 25h2v2h-2z" />
      <path d="m53 29h2v2h-2z" />
      <path d="m57 29h2v2h-2z" />
      <path d="m57 33h2v2h-2z" />
      <path d="m53 33h2v2h-2z" />
      <path d="m63 13v-3c0-3.859-3.141-7-7-7s-7 3.141-7 7v3h2v4h-2v20.762c-.713-1.473-1.374-2.969-2-4.48v-20.282h-2v-8h-3v-4h-16v4h-3v8h-2v20h-8v4h-8v18.685c-2.361 1.127-4 3.53-4 6.315v1h62v-2h-6v-6.103c3.386-.627 6-4.372 6-8.897v-29h-2v-4zm-12-3c0-2.757 2.243-5 5-5s5 2.243 5 5v1h-10zm2 3h2v4h-2zm-8 33v-3.465l2.555-1.703-1.109-1.664-1.446.964v-2.132h-2v4.586l-1.293-1.293-1.414 1.414 2.707 2.707v.586h-6.201c2.71-4.44 5.024-9.144 6.892-14h.619c1.867 4.856 4.182 9.56 6.892 14zm-17-43h12v2h-12zm-3 4h18v6h-2v-4h-2v4h-2v-4h-2v4h-2v-4h-2v4h-2v-4h-2v4h-2zm-2 8h22v15h-2v-5h-18v6h16.922c-1.947 5.121-4.396 10.072-7.297 14.715l-1.429 2.285h9.804v7.1c-1.336-1.375-3.221-2.205-5.297-2.093-.87-2.912-3.599-5.007-6.703-5.007v-15h-8zm18 12v2h-14v-2zm-26 8h14v13.307c-2.286.679-4.099 2.475-4.743 4.809-.949-.176-1.923-.149-2.853.069-1.102-2.507-3.606-4.185-6.404-4.185zm-8 4h6v10.295c-.723.216-1.396.546-2 .969v-1.264h-2v2h1.114c-1.076 1.051-1.819 2.441-2.043 4-.023 0-.048 0-.071 0-.34 0-.672.033-1 .08zm1 18c.26 0 .534.025.837.076l1.163.109v-1.185c0-2.757 2.243-5 5-5 2.248 0 4.23 1.518 4.82 3.689l.294 1.083 1.041-.416c1.13-.449 2.367-.477 3.541-.055l1.199.433.134-1.268c.27-2.546 2.407-4.466 4.971-4.466 2.45 0 4.572 1.827 4.937 4.25l.154 1.021 1.014-.186c.316-.057.608-.085.895-.085 2.757 0 5 2.243 5 5v1h-39.899c.464-2.279 2.484-4 4.899-4zm37 4v-1-12h4.175c.637 3.631 2.97 6.371 5.825 6.899v6.101zm11-8c-2.231 0-4.164-2.086-4.787-5h3.59l-1.428-2.285c-.615-.984-1.197-1.989-1.771-2.999.872-2.265 2.556-3.716 4.396-3.716 2.757 0 5 3.141 5 7s-2.243 7-5 7zm0-16c-1.93 0-3.71 1.021-5 2.718v-20.718h10v20.714c-1.272-1.672-3.042-2.714-5-2.714zm3-20h-2v-4h2z" />
    </g>
  </svg>
);

export default OtherBuildingsIcon;
