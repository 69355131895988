import FactoryIcon from '@/components/icons/factory-icon';
import HomeIcon from '@/components/icons/home-icon';
import OtherBuildingsIcon from '@/components/icons/other-buildings-icon';
import MainLayout from '@/components/main-layout';
import SEO from '@/components/seo';
import { Link } from 'gatsby';
import React from 'react';

const Projekty: React.FC = () => (
  <>
    <SEO
      title="Projekty"
      description="Pozrite si ukážku našich projektov."
      image="/projects.jpg"
    />
    <MainLayout>
      <div className="container m-auto md:px-20 px-4 py-20 flex justify-center items-center text-center text-xl flex-wrap">
        <Link to="/projekty/rodinne-a-bytove-stavby">
          <div
            style={{ backgroundColor: '#0f161d' }}
            className="px-6 py-12 flex flex-col justify-center items-center text-white w-72 h-72 shadow-2xl my-4"
          >
            <div className="flex-shrink-0">
              <HomeIcon />
            </div>
            <h2 className="mt-4">Rodinné a bytové stavby</h2>
          </div>
        </Link>
        <Link to="/projekty/obchodne-a-priemyselne-stavby">
          <div
            style={{ backgroundColor: '#0f161d' }}
            className="px-6 py-12 flex flex-col justify-center items-center text-white mx-10 w-72 h-72 shadow-2xl my-4"
          >
            <div className="flex-shrink-0">
              <FactoryIcon />
            </div>
            <h2 className="mt-4">
              Stavby obchodného a priemyselného charakteru
            </h2>
          </div>
        </Link>
        <Link to="/projekty/ostatne-stavby">
          <div
            style={{ backgroundColor: '#0f161d' }}
            className="px-6 py-12 flex flex-col justify-center items-center text-white w-72 h-72 shadow-2xl my-4"
          >
            <div className="flex-shrink-0">
              <OtherBuildingsIcon />
            </div>
            <h1 className="mt-4">Ostatné stavby</h1>
          </div>
        </Link>
      </div>
    </MainLayout>
  </>
);

export default Projekty;
